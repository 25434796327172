<template>
  <section class="conOfFindHematologist h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <HeaderPage :headerPage="{ part1: 'Find', part2: 'Hematologist' }" />
        </v-col>
      </v-row>
      <form class="conFormSubmit">
        <v-row>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.region_id"
                :items="Regionitems"
                item-text="title"
                item-value="id"
                label="Select Region"
                hide-details="auto"
                @change="loadCities(search.region_id)"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
              ></v-select>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.city_id"
                :items="Cityitems"
                item-text="title"
                item-value="id"
                label="Select City"
                hide-details="auto"
                @change="loadAreai(search.city_id)"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
              ></v-select>
            </div>
          </v-col>

          <v-col md="3" sm="6" cols="12">
            <div class="conSelectOption">
              <v-select
                outlined
                v-model="search.area_id"
                :items="Areaitems"
                item-text="title"
                item-value="id"
                label="Select Area"
                hide-details="auto"
                :menu-props="{ bottom: true, offsetY: true }"
                attach
              ></v-select>
            </div>
          </v-col>
          <v-col md="3" sm="6" cols="12">
            <div class="confBtns">
              <v-btn
                class="srearchBnt primaryBtn mr-4"
                @click="onSearch"
                :disabled="isLoadingSearch"
                :loading="isLoadingSearch"
              >
                Search
              </v-btn>
              <v-btn class="clearBtn secondary-outline-btn" @click="onClear">
                clear
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </form>
    </v-container>

    <section class="mt-3" v-if="listItems && listItems.length > 0">
      <v-container>
        <v-row>
          <v-col
            md="3"
            sm="6"
            cols="12"
            v-for="(item, index) in listItems"
            :key="index"
          >
            <div class="conofcard">
              <v-card class="mx-auto" max-width="370" tile elevation="0">
                <div class="conOfCardImg">
                  <v-img
                    height="150"
                    :src="item.image"
                    alt="item of the Image"
                  ></v-img>
                </div>
                <v-card-title class="cardTitle">
                  <div class="titleSec">
                    {{ item.name }}
                  </div>
                </v-card-title>
                <v-card-title class="areaContainer">
                  <div class="areaSec">Region, City, Area</div>
                </v-card-title>
                <v-card-text class="conOfDesc">
                  <div class="cardDesc">
                    {{ item.description }}
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <!-- 
        <v-row v-if="pagination.total > pagination.per_page">
          <v-col cols="12">
            <div class="text-center">
              <v-pagination
                v-model="pagination.current_page"
                :length="pagination.last_page"
                @input="goToPage(pagination.current_page)"
              ></v-pagination>
            </div>
          </v-col>
        </v-row> -->
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    dialog: false,
    name: "",
    menu: false,
    Regionitems: [],
    Cityitems: [],
    Areaitems: [],
    // items: [
    //   {
    //     name: "newest",
    //     value: 1,
    //   },
    //   {
    //     name: "oldest",
    //     value: 2,
    //   },
    // ],
    search: {
      region_id: null,
      city_id: null,
      area_id: null,
    },
    updatedFilter: null,
    pagination: {
      current_page: 1,
    },
    listItems: null,
    isLoading: false,
    isLoadingSearch: false,
    updatedCurrent_page: null,
  }),
  components: {
    EmptyState,
    HeaderPage,
  },
  methods: {
    onClear() {
      // this.pagination.current_page = 1;
      this.search.region_id = null;
      this.search.city_id = null;
      this.search.area_id = null;
      // this.Areaitems = null;
      // this.Cityitems = null;
      // this.pathByOaramSearch();
      this.loadCities();
      this.loadAreai();
      this.getListData();
    },
    inzializeForm() {
      debugger; // eslint-disable-line

      this.pagination.current_page = this.$route.query.current_page || 1;
      this.search.region_id = parseInt(this.$route.query.region_id) || null;
      this.search.city_id = parseInt(this.$route.query.city_id) || null;
      this.search.area_id = parseInt(this.$route.query.area_id) || null;
      if (this.search.city_id) {
        this.loadCities(this.search.region_id);
      }
      if (this.search.area_id) {
        this.loadAreai(this.search.city_id);
      }
      debugger; // eslint-disable-line
    },
    pathByOaramSearch() {
      const query = Object.entries(this.search).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      if (
        JSON.stringify(this.updatedFilter) != JSON.stringify(query) ||
        this.updatedCurrent_page != this.pagination.current_page
      ) {
        this.$router.replace({
          name: "findHematologist",
          query: { current_page: this.pagination.current_page, ...query },
        });
        this.updatedFilter = query;
        this.updatedCurrent_page = this.pagination.current_page;
      }
    },
    onSearch() {
      this.pagination.current_page = 1;
      // this.pathByOaramSearch();
      this.getListData();
    },
    goToPage() {
      // this.pathByOaramSearch();
      this.getListData();
      window.scrollTo(0, 0);
    },
    getListData() {
      this.listItems = [];
      this.isLoading = true;
      this.isLoadingSearch = true;

      apiServices
        .post("hematologists?page=" + this.pagination.current_page, this.search)
        .then((res) => {
          if (res) {
            this.listItems = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
            this.isLoadingSearch = false;
          } else {
            this.isLoading = false;
            this.isLoadingSearch = false;
          }
        });
    },

    getRegionList() {
      this.Regionitems = [];
      apiServices.get("hematologist-regions").then((res) => {
        if (res) {
          this.Regionitems = res.data;
        }
      });
    },
    loadCities(id) {
      this.Cityitems = [];
      this.Areaitems = [];
      this.search.city_id = null;
      this.search.area_id = null;

      let url = id ? "/" + id : "";
      apiServices.get("hematologist-cities" + url).then((res) => {
        if (res) {
          this.Cityitems = res.data;
        }
      });
    },
    loadAreai(id) {
      this.Areaitems = [];
      this.search.area_id = null;
      let url = id ? "/" + id : "";
      apiServices.get("hematologist-areas" + url).then((res) => {
        if (res) {
          this.Areaitems = res.data;
        }
      });
    },
  },
  // watch: {
  //   "$route.params.query": {
  //     handler: function () {
  //        this.inzializeForm();
  //       this.getListData();
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  created() {
    this.getRegionList();
    this.loadCities();
    this.loadAreai();
    this.getListData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_hematologist.scss";
</style>
